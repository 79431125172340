const constant = {
  // api: "http://localhost:8888/api/",
  // url: "http://localhost:8888/",

  url: "https://www.api.orient5.com/",
  api: "https://www.api.orient5.com/api/",
  
  timeSlots: [
    '15:30',
    '16:40',
    '17:40',
    '20:00',
    '21:10', 
    '22:22', 
    '23:30',
    '00:40',
    '01:50',
    '03:00',
    '04:10'
  ]

};
export default constant;
